import React, { useState, useEffect } from 'react'
import '../App.css'
import axios from 'axios'
import { SERVER_URI, URL_EXTENSION } from '../utility/config'
import CancelPopup from './cancel_Popup'

function CancelOrder(props) {
  props.StopListRefresh()
  let lastUserOrder = JSON.parse(window.localStorage.getItem('latestUserOrder'))
  let phoneNumber
  if (lastUserOrder === null) {
    phoneNumber = ''
  } else {
    phoneNumber = lastUserOrder.ordererPhoneNumber
  }

  const [orders, setOrders] = useState({
    data: [],
  })

  const [userData, setUserData] = useState({
    orderId: '',
    phoneNumber: '',
  })

  const [bonus, setBonus] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [popupInfo, setPopupInfo] = useState('')
  const [showOrders, setShowOrders] = useState(false)
  const [noOrders, setNoOrders] = useState(false)
  const [toggleOrders, setToggleOrders] = useState({})

  useEffect(() => {
    const fetchData = async (index) => {
      let order = []
      let bonus
      try {
        order = await axios.post(`${SERVER_URI}/get_users_active_orders`, {
          phoneNumber,
        })
        bonus = await axios.get(`${SERVER_URI}/get_bonus/${phoneNumber}`)
        console.log('response.status: ', order.status)
        console.log(order)
        if (bonus.data.accumulatedBonus) {
          setBonus(bonus.data.accumulatedBonus)
        } else {
          setBonus('')
        }
        if (order.data.length === 0) {
          setNoOrders(true)
          setShowOrders(false)
        } else {
          setOrders({ data: order.data })
          setShowOrders(true)
          setNoOrders(false)
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          window.location.href = `${URL_EXTENSION}error`
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
      }
      setUserData({ ...userData, phoneNumber: phoneNumber })
    }
    fetchData()
    // DON'T DELETE THE COMMENT BELOW!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function formatDate(string) {
    var options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    return new Date(string).toLocaleString([], options)
  }

  const togglePopup = (ride) => {
    setIsOpen(!isOpen)
    let orderInfo = {
      rideId: ride.orderId,
      pickUpLocation: ride.pickUpLocation,
      pickUpMunicipality: ride.pickUpMunicipality,
      pickUpTime: formatDate(ride.pickUpTime),
    }
    setPopupInfo(orderInfo)
  }

  const toggleOrder = (id) => {
    setToggleOrders((prevShow) => ({ ...prevShow, [id]: !prevShow[id] }))
  }

  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const getOrder = async (e) => {
    e.preventDefault()
    try {
      let order = []
      let bonus
      order = await axios.post(
        `${SERVER_URI}/get_users_active_orders`,
        userData
      )
      bonus = await axios.get(`${SERVER_URI}/get_bonus/${userData.phoneNumber}`)
      // setShow(!show);
      if (bonus.data.accumulatedBonus) {
        setBonus(bonus.data.accumulatedBonus)
      } else {
        setBonus('')
      }
      if (order.data.length === 0) {
        setNoOrders(true)
        setShowOrders(false)
      } else {
        setOrders({ data: order.data })
        setShowOrders(true)
        setNoOrders(false)
      }
    } catch (e) {
      setBonus('')
      setOrders({ data: [] })
      setShowOrders(false)
      setNoOrders(true)
      console.log('Error in getting orders', e)
    }
  }

  return (
    <div className='order'>
      <div>
        <h2 className='cancel_title'>Hae tilauksia</h2>

        <form className='cancel_form'>
          <label htmlFor='phoneNumber'>Puhelinnumero:</label>
          <input
            type='tel'
            id='phoneNumber'
            name='phoneNumber'
            placeholder='Puhelinnumero'
            onChange={onChange}
            value={userData.phoneNumber}
          ></input>
          <br />
          <button
            className='gradient_Button'
            value='Hae tilaukset'
            onClick={getOrder}
          >
            Hae tilaukset
          </button>
        </form>
        <h4 className='cancel_form'>Kertyneet bonukset: {bonus}€</h4>

        <div className={noOrders === true ? 'noOrders' : 'yesOrders'}>
          <p>Ei tilauksia</p>
        </div>
        <div className={showOrders === true ? 'showRides' : 'noRides'}>
          {orders.data.map((ride, index) => (
            <div
              className='ride'
              key={index}
              id={ride.orderId}
              onClick={() => toggleOrder(ride.orderId)}
            >
              {orders.data[index].pickUpLocation},
              {orders.data[index].pickUpMunicipality}
              <br className='mobile-break' />
              <div
                className={
                  !isOpen && toggleOrders[ride.orderId] ? 'show' : 'hide'
                }
              >
                <span>Tilausnumero: </span> <br className='mobile-break' />
                {orders.data[index].orderId}
                <br />
                <span>Nouto-osoite: </span> <br className='mobile-break' />
                {orders.data[index].pickUpLocation},{' '}
                <br className='mobile-break' />
                {orders.data[index].pickUpMunicipality}
                <br />
                <span>Matkustajan puhelinnumero: </span>
                {orders.data[index].passengerPhoneNumber}
                <br />
                <span>Matkustajan nimimerkki: </span>{' '}
                <br className='mobile-break' />
                {orders.data[index].passengerCallSign}
                <br />
                <span>Matkustajien määrä: </span>{' '}
                <br className='mobile-break' />
                {orders.data[index].passengerCount}
                <br />
                <span>Kohde-osoite: </span> <br className='mobile-break' />
                {orders.data[index].targetLocation},{' '}
                <br className='mobile-break' />{' '}
                {orders.data[index].targetMunicipality}
                <br />
                <span>Nouto-aika: </span> <br className='mobile-break' />
                {formatDate(orders.data[index].pickUpTime)}
                <br />
                <button
                  className='gradient_Button'
                  value='Peruuta tilaus'
                  onClick={() => togglePopup(ride)}
                >
                  Peruuta tilaus
                </button>
              </div>
            </div>
          ))}
          {isOpen && (
            <CancelPopup handleClose={togglePopup} orderInfo={popupInfo} />
          )}
        </div>
      </div>
    </div>
  )
}

export default CancelOrder
