import React, { useState } from 'react'
import '../App.css'
import axios from 'axios'
import { SERVER_URI } from '../utility/config'

function CancelMessage() {
  window.setTimeout(function () {
    window.location.href = `${process.env.PUBLIC_URL}/orders`
  }, 1300)
  return (
    <div>
      <h2 className='order_title'>Tilaus peruutettu</h2>
    </div>
  )
}

function CancelPopup(props) {
  const [show, setShow] = useState(false)
  const [userData, setUserData] = useState({
    orderId: '',
    phoneNumber: '',
  })
  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  // Submit
  const doSubmit = async () => {
    console.log('Tilaus peruttu')
    const dataToServer = {
      ...userData,
      orderId: Number(userData.orderId),
    }
    try {
      await axios.post(`${SERVER_URI}/cancel_order`, dataToServer)
      setShow(!show)
    } catch (e) {
      console.log('Error in deleting the order', e)
    }
  }

  return (
    <div className='order'>
      <div className='popup-box'>
        <div className='box'>
          <span className='close-icon' onClick={props.handleClose}>
            x
          </span>
          {!show && (
            <div>
              <h2 className='order_title'>Haluatko peruuttaa tilauksen?</h2>
              <span>Tilausnumero: </span>
              <br className='mobile-break' />
              {props.orderInfo.rideId}
              <br />
              <span>Nouto-osoite: </span>
              <br className='mobile-break' />
              {props.orderInfo.pickUpLocation},
              {props.orderInfo.pickUpMunicipality}
              <br />
              <span>Nouto-aika: </span>
              <br className='mobile-break' />
              {props.orderInfo.pickUpTime}
              <br />
              <form className='cancel_form'>
                <label className='cancel_label' htmlFor='phoneNumber'>
                  Tilaajan puhelinnumero:
                </label>
                <input
                  type='tel'
                  id='phoneNumber'
                  name='phoneNumber'
                  placeholder='Puhelinnumero'
                  onChange={onChange}
                ></input>
                <br />
                <label className='cancel_label' htmlFor='orderId'>
                  Tilausnumero:
                </label>
                <input
                  type='number'
                  id='orderId'
                  name='orderId'
                  placeholder='Tilausnumero'
                  onChange={onChange}
                ></input>
                <br />
              </form>
              <input
                type='submit'
                className='gradient_Button'
                value='Peruuta tilaus'
                onClick={doSubmit}
              />
            </div>
          )}
          {show && <CancelMessage />}
        </div>
      </div>
    </div>
  )
}

export default CancelPopup
